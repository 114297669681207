import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35f2fc24 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _ade8f1f4 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _ab15b55e = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _b4954518 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _309addaa = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _7f3e76f6 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _f1cafa58 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _218c24de = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _0c032fae = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _734e2e03 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _297fff64 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _7b08cf6a = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _49d82df7 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _40ce69cf = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _ed2cedac = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _f94f8498 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _3236c585 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _35f2fc24,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _ade8f1f4,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _ab15b55e,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _b4954518,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _309addaa,
    name: "receipt"
  }, {
    path: "/search",
    component: _7f3e76f6,
    name: "search"
  }, {
    path: "/search-booking",
    component: _f1cafa58,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _218c24de,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _0c032fae,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _734e2e03,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _297fff64,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _7b08cf6a,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _49d82df7,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _40ce69cf,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _ed2cedac,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _f94f8498,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _3236c585,
    name: "index"
  }, {
    path: "/dedicated",
    component: _3236c585,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _7f3e76f6,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _ed2cedac,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _7b08cf6a,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _309addaa,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
